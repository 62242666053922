import Swiper, { Pagination, Navigation, EffectFade, Thumbs } from "swiper";
import LazyLoad from "vanilla-lazyload";

(function() {

	// --------------------------------------------------------

	var lazyLoadInstance = function() {
		new LazyLoad();
	}

	window.addEventListener('load', lazyLoadInstance);

	// --------------------------------------------------------

	// KV
	let kvSwiper = null;

	function buildKvSwiper() {
		kvSwiper = new Swiper('#kv-swiper', {
			modules: [Pagination, Navigation, EffectFade],
			// Disable preloading of all images
			speed: 500,
			slidesPerView: 1,
			spaceBetween: 0,
			watchOverflow: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			navigation: {
				nextEl: '.section-kv .swiper-button-next',
				prevEl: '.section-kv .swiper-button-prev',
			},
			pagination: {
				el: '.section-kv .swiper-pagination',
				clickable: true,
			},
		});
	}

	window.addEventListener('load', buildKvSwiper);

	// --------------------------------------------------------

	// Feature
	let featureSwiper = null;

	function buildFeatureSwiper() {
		featureSwiper = new Swiper('#feature-swiper', {
			modules: [Pagination, Navigation],
			// Disable preloading of all images
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 32,
			watchOverflow: true,
			navigation: {
				nextEl: '.section-feature .swiper-button-next',
				prevEl: '.section-feature .swiper-button-prev',
			},
			pagination: {
				el: '.section-feature .swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
					spaceBetween: 32
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 60
				},
				1920: {
					slidesPerView: 3,
					spaceBetween: 155
				}
			}
		});
	}

	window.addEventListener('load', buildFeatureSwiper);

	// --------------------------------------------------------

	// Notice
	let noticeSwiper = null;

	function buildNoticeSwiper() {
		noticeSwiper = new Swiper('#notice-swiper', {
			modules: [Pagination, Navigation],
			// Disable preloading of all images
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 24,
			watchOverflow: true,
			pagination: {
				el: '.section-notice .swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
					spaceBetween: 24
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 60
				},
				1440: {
					slidesPerView: 3,
					spaceBetween: 110
				}
			}
		});
	}

	window.addEventListener('load', buildNoticeSwiper);

	// --------------------------------------------------------

	// Report

	let reportSwiper = null, reportThumbsSwiper = null;

	function buildReportSwiper() {
		reportThumbsSwiper = new Swiper("#report-thumbs-swiper", {
			spaceBetween: 24,
      slidesPerView: 1,
			freeMode: true,
      watchSlidesProgress: true,
			preventClicks :false,
			slideToClickedSlide: true,
			breakpoints: {
				768: {
					slidesPerView: 4,
					spaceBetween: 8,
				},
				1440: {
					slidesPerView: 4,
					spaceBetween: 16
				},
				1920: {
					slidesPerView: 4,
					spaceBetween: 24
				}
			}
    });

		reportSwiper = new Swiper('#report-swiper', {
			modules: [Pagination, Navigation, EffectFade, Thumbs],
			// Disable preloading of all images
			speed: 500,
			slidesPerView: 1,
			spaceBetween: 24,
			watchOverflow: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: '.section-report .swiper-pagination',
				clickable: true,
			},
			thumbs: {
        swiper: reportThumbsSwiper,
      },
		});
	}

	window.addEventListener('load', buildReportSwiper);

	// --------------------------------------------------------

	// Tool
	let  toolsSwiper = null;

	function buildToolsSwiper() {
		 toolsSwiper = new Swiper('#tool-swiper', {
			modules: [Pagination, Navigation],
			// Disable preloading of all images
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 32,
			watchOverflow: true,
			navigation: {
				nextEl: '.section-tools .swiper-button-next',
				prevEl: '.section-tools .swiper-button-prev',
			},
			pagination: {
				el: '.section-tools .swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				1440: {
					slidesPerView: 5,
					spaceBetween: 32
				},
			}
		});
	}

	window.addEventListener('load', buildToolsSwiper);

	// --------------------------------------------------------
})();
